:root{
    --background-dark: #2d3548;
    --text-light: rgba(255,255,255,0.6);
    --text-lighter: rgba(255,255,255,0.9);
    --spacing-s: 8px;
    --spacing-m: 16px;
    --spacing-l: 24px;
    --spacing-xl: 32px;
    --spacing-xxl: 64px;
    --width-container: 1200px;
  }

  .hidden{
    display: none;
  }

  .mirror-180{
    
  }

.card-section{
    align-items:  flex-start;
    display: flex;
    min-height: 100%;
    justify-content: center;
    padding: var(--spacing-m) var(--spacing-l);
  }
  
  .card-grid{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: var(--spacing-s);
    grid-row-gap: var(--spacing-l);
    max-width: var(--width-container);
    width: 100%;
  }
  
  @media(min-width: 540px){
    .card-grid{
      grid-template-columns: repeat(2, 1fr); 
    }
  }
  
  @media(min-width: 960px){
    .card-grid{
      grid-template-columns: repeat(4, 1fr); 
    }
  }

  @media(min-width: 1200px){
    .card:hover .card__addon  {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 1; 
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    .card:hover .train{
      scale: 0.8;
      transform: translate(-220px,120px);
      transition: .35s ease-in-out;
    }
    .card:hover .truck{
      scale: 0.5;
      transform: translate(-350px,200px);
      transition: .35s ease-in-out;
    }
    .card:hover .plane{
      scale: 0.8;
      transform: translate(-300px,180px);
      transition: .35s ease-in-out;
    }
    .card:hover .helicopter{
      scale: 0.9;
      transform: translate(-260px,100px);
      transition: .35s ease-in-out;
    }
  }
  
  .card{
    list-style: none;
    position:relative;
    margin: 10px;
  }
  
  .card:before{
    content: '';
    display: block;
    padding-bottom: 150%;
    width: 100%;
  }
  
  .card__background{
    background-size: cover;
    background-position: center;
    border-radius: var(--spacing-l);
    bottom: 0;
    filter: brightness(0.75) saturate(1.2) contrast(0.85);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: center;
    transform: scale(1) translateZ(0);
    transition:
      filter 200ms linear,
      transform 200ms linear;
    filter: blur(0.5);
    z-index: 2;
  }
  
  .card:hover .card__background{
    transform: scale(1.05) translateZ(0);
  }

  .card-grid:hover > .card:not(:hover) .card__background{
    filter: brightness(0.5) saturate(0) contrast(0.8) blur(10px);
  }
  
  .card__content{
    left: 0;
    padding: var(--spacing-l);
    position: absolute;
    top: 0;
    z-index: 3;
  }
  
  .card__category{
    color: var(--text-lighter);
    font-size: 1.05rem;
    text-shadow: 2px 2px 20px rgba(0,0,0,0.2);
    line-height: 1;
    margin-bottom: var(--spacing-s);
    text-transform: uppercase;
    font-weight: bolder;
  }
  
  .card__heading{
    color: var(--text-lighter);
    font-size: 1.9rem;
    text-shadow: 2px 2px 20px rgba(0,0,0,0.2);
    line-height: 1.1;
    font-weight: bold;
    word-spacing: 100vw;
    margin-bottom: 2rem;
  }

  .card__paragraph{
    color: var(--text-lighter);
    font-size: 0.9rem;
    text-shadow: 2px 2px 20px rgba(0,0,0,0.2);
    line-height: 1;
    margin-bottom: var(--spacing-s);
    text-transform: uppercase;
    font-weight: bolder;
  }
  